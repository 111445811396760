import React, { useState, useEffect } from 'react'
import { signIn, resetPassword } from './auth'
import { useNavigate } from 'react-router-dom'
import { supabase } from './supabaseClient'
import logoImage from './assets/Dharmalab.webp'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "./components/ui/card"
import { Mail } from 'lucide-react' // Import the Mail icon from lucide-react
import './Login.css'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [resetEmail, setResetEmail] = useState('')
  const [resetError, setResetError] = useState(null)
  const [resetSuccess, setResetSuccess] = useState(false)
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // Attempt to register the mailto protocol handler
    try {
      navigator.registerProtocolHandler('mailto',
        `${window.location.origin}/email-handler?to=%s`,
        'DharmaLab Email Handler'
      );
    } catch (error) {
      console.error('Failed to register protocol handler:', error);
    }
  }, []);

  const openEmailApp = () => {
    window.location.href = `mailto:${email}`;
  };

  const handleSignIn = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    try {
      await signIn(email, password)
      navigate('/dashboard')
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSignUp = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
          }
        }
      })
      if (error) throw error
      setRegistrationSuccess(true)
    } catch (err) {
      console.error('Sign-up error:', err)
      if (err.status === 429) {
        setError('Too many sign-up attempts. Please try again later.')
      } else {
        setError('An error occurred during sign-up. Please try again.')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleResetPassword = async (e) => {
    e.preventDefault()
    setLoading(true)
    setResetError(null)
    setResetSuccess(false)
    try {
      await resetPassword(resetEmail)
      setResetSuccess(true)
    } catch (err) {
      setResetError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const renderLoginForm = () => (
    <>
      <form className="space-y-4" onSubmit={handleSignIn}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          required
        />
        <Button className="w-full" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Sign In'}
        </Button>
      </form>
      <p className="text-sm text-center mt-4">
        <span className="cursor-pointer text-blue-500 hover:underline" onClick={() => setShowResetPassword(true)}>
          Forgot password?
        </span>
      </p>
      <p className="text-sm text-center mt-2">
        New to Dharmalab? <span className="cursor-pointer text-blue-500 hover:underline" onClick={() => setShowSignUp(true)}>Sign up now</span>
      </p>
    </>
  )

  const renderSignUpForm = () => (
    <>
      {registrationSuccess ? (
        <div className="text-center">
          <h3 className="text-lg font-semibold mb-2">Registration Successful!</h3>
          <p className="mb-4">Please check your email to confirm your account.</p>
          <Button 
            className="w-full mb-2 bg-white text-blue-500 hover:bg-blue-100" 
            onClick={openEmailApp}
          >
            <Mail className="mr-2 h-4 w-4" /> Open Email
          </Button>
          <Button 
            className="w-full" 
            onClick={() => {
              setShowSignUp(false)
              setRegistrationSuccess(false)
            }}
          >
            Back to Login
          </Button>
        </div>
      ) : (
        <form className="space-y-4" onSubmit={handleSignUp}>
          <Input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={loading}
            required
          />
          <Input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={loading}
            required
          />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            required
          />
          <Input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading}
            required
          />
          <Button className="w-full" type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Sign Up'}
          </Button>
        </form>
      )}
      {!registrationSuccess && (
        <p className="text-sm text-center mt-4">
          Already have an account? <span className="cursor-pointer text-blue-500 hover:underline" onClick={() => setShowSignUp(false)}>Sign in</span>
        </p>
      )}
    </>
  )

  const renderResetPasswordForm = () => (
    <form className="space-y-4" onSubmit={handleResetPassword}>
      <Input
        type="email"
        placeholder="Email"
        value={resetEmail}
        onChange={(e) => setResetEmail(e.target.value)}
        disabled={loading}
        required
      />
      <Button className="w-full" type="submit" disabled={loading}>
        {loading ? 'Sending...' : 'Send Reset Email'}
      </Button>
      <p className="text-sm text-center mt-4">
        <span className="cursor-pointer text-blue-500 hover:underline" onClick={() => setShowResetPassword(false)}>
          Back to Login
        </span>
      </p>
    </form>
  )

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-200 via-purple-100 to-blue-300 flex items-center justify-center p-8 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="moving-text text1">Mindfulness</div>
        <div className="moving-text text2">Meditation</div>
        <div className="moving-text text3">Inner Peace</div>
        <div className="moving-text text4">Self-Discovery</div>
        <div className="moving-text text5">Harmony</div>
      </div>

      <div className="w-full max-w-7xl flex flex-col md:flex-row justify-between items-stretch z-10">
        <div className="w-full md:w-5/12 max-w-md mb-8 md:mb-0">
          <Card className="backdrop-blur-sm bg-white/30 shadow-lg border-0 h-full flex flex-col">
            <CardHeader>
              <img src={logoImage} alt="Dharmalab Logo" className="w-16 h-16 mx-auto mb-4" />
              <CardTitle className="text-center text-black">Welcome to DharmaLab</CardTitle>
              <CardDescription className="text-center text-black">Your personal meditation and mindfulness companion</CardDescription>
            </CardHeader>
            <CardContent className="flex-grow">
              <ul className="list-disc list-inside space-y-2 text-black">
                <li>Guided meditations for all levels</li>
                <li>Daily mindfulness exercises</li>
                <li>Progress tracking and insights</li>
                <li>Community support and discussions</li>
              </ul>
            </CardContent>
            <CardFooter>
              <p className="text-sm text-center text-black">Join us on a journey to inner peace and self-discovery.</p>
            </CardFooter>
          </Card>
        </div>

        <div className="w-full md:w-5/12 max-w-md">
          <Card className="backdrop-blur-sm bg-white/30 shadow-lg border-0 h-full flex flex-col justify-center">
            <CardHeader>
              <CardTitle className="text-center text-black">{showSignUp ? 'Create an Account' : 'Access Your Account'}</CardTitle>
            </CardHeader>
            <CardContent>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              {showResetPassword
                ? renderResetPasswordForm()
                : showSignUp
                ? renderSignUpForm()
                : renderLoginForm()}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Login
