import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import Player from './Player';
import logoImage from '../assets/Dharmalab.png';
import { FiHeart, FiSearch, FiHome, FiMusic, FiBook, FiMessageSquare, FiVideo, FiPlus } from 'react-icons/fi';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Button } from "../components/ui/button";
import './DashboardLayout.css';
import { usePlaylist } from '../PlaylistContext';
import CreatePlaylistModal from './CreatePlaylistModal';
import { FaPlay, FaPause } from 'react-icons/fa';
import LikeLoadingSpinner from './LikeLoadingSpinner';
import TrackOptionsMenu from './TrackOptionsMenu';
import PlaylistView from './PlaylistView';
import CreatePlaylistButton from './CreatePlaylistButton';

function DashboardLayout() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [likedTracks, setLikedTracks] = useState({});
  const [loadingLikes, setLoadingLikes] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(false);
  const { playlists, createPlaylist, fetchPlaylists } = usePlaylist();

  const searchRef = useRef(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (session?.user?.id) {
      fetchLikedTracks();
      fetchPlaylists();
    }
  }, [session]);

  async function fetchLikedTracks() {
    const { data, error } = await supabase
      .from('liked_tracks')
      .select('*')
      .eq('user_id', session.user.id);

    if (error) {
      console.error('Error fetching liked tracks:', error);
    } else {
      const likedTracksObj = data.reduce((acc, item) => {
        acc[item.track_id] = {
          id: item.id,
          title: item.title,
          artist: item.artist,
          album: item.album,
          artwork_url: item.artwork_url
        };
        return acc;
      }, {});
      setLikedTracks(likedTracksObj);
    }
  }

  async function handleLikeClick(track) {
    if (!session) {
      console.log('User not logged in');
      return;
    }

    setLoadingLikes(prev => ({ ...prev, [track.id]: true }));

    const isCurrentlyLiked = likedTracks[track.id];

    try {
      if (isCurrentlyLiked) {
        await supabase
          .from('liked_tracks')
          .delete()
          .eq('user_id', session.user.id)
          .eq('track_id', track.id);

        setLikedTracks(prev => {
          const newLikedTracks = { ...prev };
          delete newLikedTracks[track.id];
          return newLikedTracks;
        });
      } else {
        const { data, error } = await supabase
          .from('liked_tracks')
          .insert({
            user_id: session.user.id,
            track_id: track.id,
            title: track.title,
            artist: track.artist,
            album: track.album,
            artwork_url: track.artwork_url,
            audio_url: track.audio_url,
            duration: track.duration
          })
          .select();

        if (error) throw error;

        setLikedTracks(prev => ({
          ...prev,
          [track.id]: {
            user_id: session.user.id,
            track_id: track.id,
            title: track.title,
            artist: track.artist,
            album: track.album,
            artwork_url: track.artwork_url,
            audio_url: track.audio_url,
            duration: track.duration
          }
        }));
      }
    } catch (error) {
      console.error('Error updating liked track:', error);
    } finally {
      setLoadingLikes(prev => ({ ...prev, [track.id]: false }));
    }
  }

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSearchInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 2) {
      const { data, error } = await supabase
        .from('meditations')
        .select('id, title, artist, album, artwork_url')
        .or(`title.ilike.%${value}%,artist.ilike.%${value}%,album.ilike.%${value}%`)
        .limit(5);

      if (error) {
        console.error('Error searching meditations:', error);
      } else {
        setSearchResults(data);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/dashboard/search?q=${encodeURIComponent(searchTerm)}`);
      setSearchResults([]);
    }
  };

  const handleSuggestionClick = (album) => {
    navigate(`/dashboard/streaming`, { state: { selectedAlbum: album } });
    setSearchResults([]);
    setSearchTerm('');
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCreatePlaylist = async (title, description, emoji) => {
    try {
      await createPlaylist(title, description, emoji);
      setIsCreatePlaylistModalOpen(false);
      fetchPlaylists();
    } catch (error) {
      console.error('Error creating playlist:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    navigate('/login');
    return null;
  }

  return (
    <div className="dashboard-layout">
      <header className="dashboard-header">
        <div className="header-content">
          <div className="logo-container">
            <img src={logoImage} alt="Dharmalab Logo" className="app-logo" />
          </div>
          <div className="search-container" ref={searchRef}>
            <form onSubmit={handleSearch} className="search-form">
              <input
                type="text"
                placeholder="Search meditations..."
                value={searchTerm}
                onChange={handleSearchInputChange}
                className="search-input"
              />
              <button type="submit" className="search-button">
                <FiSearch />
              </button>
            </form>
            {searchResults.length > 0 && (
              <div className="search-suggestions">
                {searchResults.map((result) => (
                  <div 
                    key={result.id} 
                    className="search-suggestion-item cursor-pointer"
                    onClick={() => handleSuggestionClick(result.album)}
                  >
                    <img src={result.artwork_url} alt={result.title} className="search-suggestion-artwork" />
                    <div className="search-suggestion-info">
                      <div className="search-suggestion-name">{result.title}</div>
                      <div className="search-suggestion-artist">{result.artist}</div>
                      <div className="search-suggestion-album">{result.album}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="user-actions">
            <Link to="/dashboard/liked-tracks" className="nav-icon-link">
              <FiHeart className="nav-icon heart-icon" />
            </Link>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="user-icon-btn">
                  <svg viewBox="0 0 24 24" className="w-6 h-6">
                    <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
                  </svg>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onSelect={() => navigate('/dashboard/profile')}>
                  Account
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={handleLogout}>
                  Sign Out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </header>

      <div className="dashboard-main">
        <aside className="sidebar">
          <nav className="sidebar-nav">
            <Link to="/dashboard" className={`sidebar-link ${isActive('/dashboard') ? 'active' : ''}`}>
              <span className="emoji">🏠</span> <span>Home</span>
            </Link>
            <Link to="/dashboard/streaming" className={`sidebar-link ${isActive('/dashboard/streaming') ? 'active' : ''}`}>
              <span className="emoji">🎵</span> <span>Streaming</span>
            </Link>
            <Link to="/dashboard/liked-tracks" className="sidebar-link submenu-link">
              <span className="emoji">❤️</span> <span>Liked Tracks</span>
            </Link>
            {Array.isArray(playlists) && playlists.map((playlist) => (
              playlist && playlist.id && (
                <Link key={playlist.id} to={`/dashboard/playlist/${playlist.id}`} className="sidebar-link submenu-link">
                  <span className="emoji">{playlist.emoji || '🎵'}</span> <span>{playlist.title}</span>
                </Link>
              )
            ))}
            <button onClick={() => setIsCreatePlaylistModalOpen(true)} className="sidebar-link submenu-link create-playlist-button">
              <span className="emoji">➕</span> <span>Create New Playlist</span>
            </button>
            <Link to="/dashboard/gratitude-journal" className={`sidebar-link ${isActive('/dashboard/gratitude-journal') ? 'active' : ''}`}>
              <span className="emoji">📓</span> <span>Journal</span>
            </Link>
            <Link to="/dashboard/ai-chatbots" className={`sidebar-link ${isActive('/dashboard/ai-chatbots') ? 'active' : ''}`}>
              <span className="emoji">💬</span> <span>Chat</span>
            </Link>
            <Link to="/dashboard/courses" className={`sidebar-link ${isActive('/dashboard/courses') ? 'active' : ''}`}>
              <span className="emoji">🎥</span> <span>Courses</span>
            </Link>
          </nav>
        </aside>

        <main className={`content-wrapper ${isPlayerVisible ? 'player-visible' : ''}`}>
          <Outlet context={{
            currentTrack,
            setCurrentTrack,
            isPlaying,
            setIsPlaying,
            likedTracks,
            handleLikeClick,
            loadingLikes,
            setLoadingLikes,
            playlists,
          }} />
        </main>
      </div>

      <Player 
        currentTrack={currentTrack}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        setIsPlayerVisible={setIsPlayerVisible}
        likedTracks={likedTracks}
        handleLikeClick={handleLikeClick}
        loadingLikes={loadingLikes}
        setLoadingLikes={setLoadingLikes}
        user={session?.user}
      />

      <CreatePlaylistModal
        isOpen={isCreatePlaylistModalOpen}
        onClose={() => setIsCreatePlaylistModalOpen(false)}
        onCreatePlaylist={handleCreatePlaylist}
      />

      <div className="mobile-menu">
        <Link to="/dashboard" className={`mobile-menu-link ${isActive('/dashboard') ? 'active' : ''}`}>
          <FiHome /> <span>Home</span>
        </Link>
        <Link to="/dashboard/streaming" className={`mobile-menu-link ${isActive('/dashboard/streaming') ? 'active' : ''}`}>
          <FiMusic /> <span>Streaming</span>
        </Link>
        <Link to="/dashboard/gratitude-journal" className={`mobile-menu-link ${isActive('/dashboard/gratitude-journal') ? 'active' : ''}`}>
          <FiBook /> <span>Journal</span>
        </Link>
        <Link to="/dashboard/ai-chatbots" className={`mobile-menu-link ${isActive('/dashboard/ai-chatbots') ? 'active' : ''}`}>
          <FiMessageSquare /> <span>Chat</span>
        </Link>
        <Link to="/dashboard/courses" className={`mobile-menu-link ${isActive('/dashboard/courses') ? 'active' : ''}`}>
          <FiVideo /> <span>Courses</span>
        </Link>
      </div>
    </div>
  );
}

export default DashboardLayout;