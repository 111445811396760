import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500">
      <header className="container mx-auto px-4 py-6 flex justify-between items-center">
        <h1 className="text-4xl font-bold text-white">DharmaLab</h1>
        <nav>
          <Link to="/login" className="bg-white text-purple-600 px-4 py-2 rounded-full mr-4 hover:bg-purple-100 transition duration-300">Login</Link>
          <Link to="/register" className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-300">Register</Link>
        </nav>
      </header>

      <section className="container mx-auto px-4 py-20 text-center">
        <h2 className="text-5xl font-bold text-white mb-8">Discover Your Path to Inner Peace and Wisdom</h2>
      </section>

      <section className="container mx-auto px-4 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold mb-4">AI Chats with Historical Minds</h4>
            <p className="text-gray-600">Engage in enlightening conversations with AI-powered representations of history's greatest thinkers.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold mb-4">Guided Meditations</h4>
            <p className="text-gray-600">Experience tranquility with our expertly crafted guided meditation sessions.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-semibold mb-4">Personalized Plans and Courses</h4>
            <p className="text-gray-600">Embark on a tailored journey of self-discovery with customized plans and courses.</p>
          </div>
        </div>
      </section>

      <section className="container mx-auto px-4 py-16 text-center text-white">
        <p className="text-xl mb-4">DharmaLab helps people improve their mental health and find their place in the world through open discourse and mindfulness practices.</p>
        <p className="text-sm">© 2024, DharmaLab</p>
      </section>
    </div>
  );
}

export default Home;