import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { OpenAI } from 'openai';
import { supabase } from '../supabaseClient';
import { logUserActivity } from '../userActivityLogger';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { ScrollArea } from "../components/ui/scroll-area";
import ReactMarkdown from 'react-markdown';
import LoadingSpinner from './LoadingSpinner';
import { FiArrowLeft, FiPlus, FiMenu, FiX } from 'react-icons/fi';
import './ChatInterface.css';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const greatMinds = {
  'carl-jung': { name: 'Carl Jung', icon: 'Carl-Jung.png' },
  'friedrich-nietzsche': { name: 'Friedrich Nietzsche', icon: 'Friedrich-Nietzsche.png' },
  'sigmund-freud': { name: 'Sigmund Freud', icon: 'Sigmund-Freud.png' },
  'alan-watts': { name: 'Alan Watts', icon: 'Alan-Watts.png' },
  'socrates': { name: 'Socrates', icon: 'Socrates.png' },
  'plato': { name: 'Plato', icon: 'Plato.png' },
  'aristotle': { name: 'Aristotle', icon: 'Aristotle.png' },
  'confucius': { name: 'Confucius', icon: 'Confucius.png' },
  'lao-tzu': { name: 'Lao Tzu', icon: 'Lao-Tzu.png' },
  'buddha': { name: 'Buddha', icon: 'Buddha.png' }
};

function ChatInterface() {
  const { mindId } = useParams();
  const [chatSessions, setChatSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const selectedMind = greatMinds[mindId];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const scrollAreaRef = useRef(null);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeSidebar();
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchChatSessions();
    }
  }, [user]);

  useEffect(() => {
    scrollToBottom();
  }, [chatSessions, currentSessionId]);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo(0, scrollAreaRef.current.scrollHeight);
    }
  }, [currentSessionId]);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  async function fetchUser() {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  }

  async function fetchChatSessions() {
    setIsPageLoading(true);
    const { data, error } = await supabase
      .from('chat_sessions')
      .select('*')
      .eq('user_id', user.id)
      .eq('mind_id', mindId)
      .order('updated_at', { ascending: false });

    if (error) {
      console.error('Error fetching chat sessions:', error);
    } else {
      setChatSessions(data || []);
      if (data && data.length > 0) {
        setCurrentSessionId(data[0].id);
        await fetchMessages(data[0].id);
      } else {
        await startNewChat();
      }
    }
    setIsPageLoading(false);
    setIsLoading(false);
  }

  async function fetchMessages(sessionId) {
    setIsPageLoading(true);
    const { data, error } = await supabase
      .from('chat_messages')
      .select('*')
      .eq('session_id', sessionId)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching messages:', error);
    } else {
      setChatSessions(prevSessions => 
        prevSessions.map(session => 
          session.id === sessionId 
            ? { ...session, messages: data.map(msg => ({ text: msg.content, user: msg.is_user })) }
            : session
        )
      );
    }
    setIsPageLoading(false);
  }

  const startNewChat = async () => {
    const { data, error } = await supabase
      .from('chat_sessions')
      .insert([
        { user_id: user.id, mind_id: mindId, title: "New Chat" }
      ])
      .select();

    if (error) {
      console.error('Error creating new chat session:', error);
    } else {
      const newSession = { id: data[0].id, messages: [], title: "New Chat", created_at: new Date().toISOString() };
      setChatSessions(prevSessions => [newSession, ...prevSessions]);
      setCurrentSessionId(newSession.id);
      setInput('');
      await logUserActivity(user.id, 'chat_started', `Started new chat with ${selectedMind.name}`);
    }
  };

  const switchChat = async (sessionId) => {
    setCurrentSessionId(sessionId);
    await fetchMessages(sessionId);
    const session = chatSessions.find(s => s.id === sessionId);
    if (session) {
      await logUserActivity(user.id, 'chat_resumed', `Resumed chat with ${selectedMind.name}`);
    }
  };

  const deleteChat = async (sessionId, e) => {
    e.stopPropagation();
    const { error } = await supabase
      .from('chat_sessions')
      .delete()
      .eq('id', sessionId);

    if (error) {
      console.error('Error deleting chat session:', error);
    } else {
      setChatSessions(prevSessions => {
        const updatedSessions = prevSessions.filter(session => session.id !== sessionId);
        if (updatedSessions.length === 0) {
          startNewChat();
        } else if (sessionId === currentSessionId) {
          setCurrentSessionId(updatedSessions[0].id);
          fetchMessages(updatedSessions[0].id);
        }
        return updatedSessions;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '' || !currentSessionId) return;

    const userMessage = { text: input, user: true };
    updateCurrentSession(userMessage);
    setInput('');
    setSessionLoading(true);

    try {
      const assistantMessage = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          { role: "system", content: `You are ${selectedMind.name}, a wise and compassionate AI.` },
          ...currentSession.messages.map(msg => ({
            role: msg.user ? "user" : "assistant",
            content: msg.text
          })),
          { role: "user", content: input }
        ],
      });

      const botReply = { text: assistantMessage.choices[0].message.content, user: false };
      updateCurrentSession(botReply);

      await saveMessages([userMessage, botReply]);

      if (user) {
        await logUserActivity(user.id, 'message_sent', `Sent message in chat with ${selectedMind.name}`);
      }
    } catch (error) {
      console.error('Error in API call:', error);
      const errorMessage = { text: `Sorry, I couldn't process that request. Error: ${error.message}`, user: false };
      updateCurrentSession(errorMessage);
    } finally {
      setSessionLoading(false);
    }
  };

  const updateCurrentSession = (message) => {
    setChatSessions(prevSessions =>
      prevSessions.map(session =>
        session.id === currentSessionId
          ? { ...session, messages: [...session.messages, message] }
          : session
      )
    );
  };

  const setSessionLoading = (isLoading) => {
    setChatSessions(prevSessions =>
      prevSessions.map(session =>
        session.id === currentSessionId
          ? { ...session, isLoading }
          : session
      )
    );
  };

  async function saveMessages(messages) {
    const { error } = await supabase
      .from('chat_messages')
      .insert(messages.map(msg => ({
        session_id: currentSessionId,
        content: msg.text,
        is_user: msg.user
      })));

    if (error) {
      console.error('Error saving messages:', error);
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const currentSession = chatSessions.find(session => session.id === currentSessionId) || { messages: [], isLoading: false };

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo(0, scrollAreaRef.current.scrollHeight);
    }
  }, [currentSession]);

  if (isPageLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="chat-interface-container h-full max-h-full flex overflow-hidden">
      {!isMobile && (
        <div className="w-64 bg-gray-100 p-4 flex flex-col h-full">
          <Link to="/dashboard/ai-chatbots">
            <Button variant="outline" className="mb-4 w-full">← Go Back</Button>
          </Link>
          <Button onClick={startNewChat} className="mb-4">+ New chat</Button>
          <div className="flex-grow overflow-hidden">
            <ScrollArea className="h-[calc(100%-100px)]">
              <h3 className="font-semibold mb-2">Chat Sessions</h3>
              {isLoading ? (
                <p>Loading...</p>
              ) : chatSessions.length === 0 ? (
                <p>No chat sessions yet.</p>
              ) : (
                chatSessions.map((session) => (
                  <div
                    key={session.id}
                    className={`p-2 mb-2 rounded cursor-pointer ${session.id === currentSessionId ? 'bg-blue-100' : 'hover:bg-gray-200'}`}
                    onClick={() => switchChat(session.id)}
                  >
                    <div className="flex justify-between items-center">
                      <span className="truncate">{session.title}</span>
                      <Button 
                        variant="ghost" 
                        size="sm"
                        onClick={(e) => deleteChat(session.id, e)}
                      >
                        ×
                      </Button>
                    </div>
                    <span className="text-xs text-gray-500">{formatDate(session.created_at)}</span>
                  </div>
                ))
              )}
            </ScrollArea>
          </div>
        </div>
      )}
      <div className="flex-grow flex flex-col h-full relative">
        <Card className="flex-grow flex flex-col h-full">
          <CardHeader className="flex-shrink-0">
            <CardTitle className="flex items-center justify-between">
              {isMobile && (
                <Link to="/dashboard/ai-chatbots" className="mr-2">
                  <Button variant="ghost" size="sm"><FiArrowLeft /></Button>
                </Link>
              )}
              <span>{selectedMind.name}</span>
              {isMobile && (
                <Button variant="ghost" size="sm" onClick={toggleSidebar}>
                  <FiMenu />
                </Button>
              )}
            </CardTitle>
          </CardHeader>
          <CardContent className="flex-grow overflow-hidden p-0">
            <ScrollArea className="h-full" ref={scrollAreaRef}>
              <div className="p-4 messages-container">
                {currentSession && currentSession.messages && currentSession.messages.map((message, index) => (
                  <div key={index} className={`mb-4 flex ${message.user ? 'justify-end' : 'justify-start'}`}>
                    {!message.user && (
                      <img 
                        src={require(`../components/mind-icons/${selectedMind.icon}`)} 
                        alt={`${selectedMind.name} icon`} 
                        className="w-8 h-8 rounded-full mr-2 self-end"
                      />
                    )}
                    <div className={`p-2 rounded-lg ${message.user ? 'bg-blue-100' : 'bg-gray-100'} message-content`}>
                      {message.user ? (
                        <span>{message.text}</span>
                      ) : (
                        <ReactMarkdown>{message.text}</ReactMarkdown>
                      )}
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
            </ScrollArea>
          </CardContent>
          <form onSubmit={handleSubmit} className="p-4 bg-gray-100">
            <div className="flex">
              <Input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Ask a question..."
                disabled={isLoading || (currentSession && currentSession.isLoading)}
                className="flex-grow mr-2"
              />
              <Button type="submit" disabled={isLoading || (currentSession && currentSession.isLoading)}>
                Send
              </Button>
            </div>
          </form>
        </Card>
        {isMobile && (
          <>
            <div className={`mobile-sidebar-overlay ${isSidebarOpen ? 'open' : ''}`} onClick={closeSidebar}></div>
            <div className={`mobile-sidebar ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Menu</h2>
                <Button variant="ghost" size="sm" onClick={closeSidebar}>
                  <FiX />
                </Button>
              </div>
              <Button onClick={startNewChat} className="mb-4 w-full">+ New chat</Button>
              <ScrollArea className="h-[calc(100%-100px)]">
                <h3 className="font-semibold mb-2">Chat Sessions</h3>
                {isLoading ? (
                  <p>Loading...</p>
                ) : chatSessions.length === 0 ? (
                  <p>No chat sessions yet.</p>
                ) : (
                  chatSessions.map((session) => (
                    <div
                      key={session.id}
                      className={`p-2 mb-2 rounded cursor-pointer ${session.id === currentSessionId ? 'bg-blue-100' : 'hover:bg-gray-200'}`}
                      onClick={() => {
                        switchChat(session.id);
                        closeSidebar();
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <span className="truncate">{session.title}</span>
                        <Button 
                          variant="ghost" 
                          size="sm"
                          onClick={(e) => deleteChat(session.id, e)}
                        >
                          ×
                        </Button>
                      </div>
                      <span className="text-xs text-gray-500">{formatDate(session.created_at)}</span>
                    </div>
                  ))
                )}
              </ScrollArea>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChatInterface;