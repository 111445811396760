import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FaPlay, FaPause } from 'react-icons/fa';
import { FiHeart, FiArrowLeft } from 'react-icons/fi';
import LoadingSpinner from './LoadingSpinner';
import LikeLoadingSpinner from './LikeLoadingSpinner';
import TrackOptionsMenu from './TrackOptionsMenu';
import { usePlaylist } from '../PlaylistContext';
import './LikedTracks.css';
import { Button } from "./ui/button";
import CreatePlaylistModal from './CreatePlaylistModal';

function PlaylistView() {
  const navigate = useNavigate();
  const { playlistId } = useParams();
  const { 
    currentTrack,
    setCurrentTrack, 
    isPlaying, 
    setIsPlaying, 
    likedTracks,
    handleLikeClick,
    loadingLikes,
    setLoadingLikes
  } = useOutletContext();
  const { removeTrackFromPlaylist, playlists, fetchPlaylists } = usePlaylist();
  const [playlist, setPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    fetchPlaylist();
    fetchPlaylists(); // Ensure playlists are up-to-date
  }, [playlistId]);

  useEffect(() => {
    // Update local state when the playlist is edited
    const updatedPlaylist = playlists.find(p => p.id === playlistId);
    if (updatedPlaylist && playlist) {
      setPlaylist(prevPlaylist => ({
        ...prevPlaylist,
        title: updatedPlaylist.title,
        description: updatedPlaylist.description,
        emoji: updatedPlaylist.emoji
      }));
    }
  }, [playlists, playlistId]);

  const fetchPlaylist = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data: playlistData, error: playlistError } = await supabase
        .from('playlists')
        .select('*')
        .eq('id', playlistId)
        .single();

      if (playlistError) throw playlistError;

      if (playlistData) {
        const { data: meditationsData, error: meditationsError } = await supabase
          .from('playlist_tracks')
          .select(`
            track_id,
            meditations (*)
          `)
          .eq('playlist_id', playlistId);

        if (meditationsError) throw meditationsError;

        setPlaylist({
          ...playlistData,
          tracks: meditationsData.map(item => item.meditations)
        });
      } else {
        setError('Playlist not found');
      }
    } catch (error) {
      console.error('Error fetching playlist:', error);
      setError('Error fetching playlist');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveTrack = async (trackId) => {
    await removeTrackFromPlaylist(playlistId, trackId);
    fetchPlaylist();
  };

  const playPause = (track) => {
    if (currentTrack && currentTrack.id === track.id) {
      setIsPlaying(!isPlaying);
    } else {
      setCurrentTrack(track);
      setIsPlaying(true);
    }
  };

  const handleLocalLikeClick = async (track, e) => {
    e.stopPropagation();
    await handleLikeClick(track);
  };

  const handleEditPlaylist = async (updatedPlaylist) => {
    setPlaylist(prevPlaylist => ({
      ...prevPlaylist,
      ...updatedPlaylist
    }));
    setIsEditModalOpen(false);
    await fetchPlaylists(); // Refresh playlists after edit
  };

  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    const parts = [
      hours > 0 ? hours.toString().padStart(2, '0') : null,
      minutes.toString().padStart(2, '0'),
      remainingSeconds.toString().padStart(2, '0')
    ].filter(Boolean);
    
    return parts.join(':');
  }

  if (loading) return <LoadingSpinner />;
  if (error) return <div>{error}</div>;
  if (!playlist) return <div>Playlist not found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-8 relative">
        <button 
          onClick={() => navigate(-1)} 
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        >
          <FiArrowLeft size={24} />
        </button>
        <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
          <div className="w-64 h-64 flex items-center justify-center bg-blue-100 rounded-lg mb-4 md:mb-0 md:mr-8">
            <span className="text-8xl">{playlist.emoji || '🎵'}</span>
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-2">{playlist.title}</h1>
            <p className="text-xl text-gray-600 mb-4">{playlist.description}</p>
            <Button 
              onClick={() => setIsEditModalOpen(true)}
              variant="outline"
              className="mb-4"
            >
              Edit Playlist
            </Button>
          </div>
        </div>
        <div className="space-y-4">
          {playlist.tracks && playlist.tracks.length > 0 ? (
            playlist.tracks.map((meditation) => (
              <div key={meditation.id} className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
                <div className="flex items-center flex-grow">
                  <img src={meditation.artwork_url} alt={`${meditation.title} cover`} className="w-16 h-16 object-cover rounded-md mr-4" />
                  <div>
                    <h3 className="font-semibold">{meditation.title}</h3>
                    <p className="text-gray-600 text-sm">{meditation.artist}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <span className="text-gray-500">{formatDuration(meditation.duration)}</span>
                  <button 
                    className={`text-red-500 hover:text-red-600 focus:outline-none ${likedTracks[meditation.id] ? 'text-red-600' : ''}`}
                    onClick={(e) => handleLocalLikeClick(meditation, e)}
                    disabled={loadingLikes[meditation.id]}
                  >
                    {loadingLikes[meditation.id] ? (
                      <LikeLoadingSpinner />
                    ) : (
                      <FiHeart className={`w-6 h-6 ${likedTracks[meditation.id] ? 'fill-current' : ''}`} />
                    )}
                  </button>
                  <button 
                    className={`text-gray-600 hover:text-gray-800 focus:outline-none ${currentTrack && currentTrack.id === meditation.id && isPlaying ? 'text-blue-500' : ''}`}
                    onClick={() => playPause(meditation)}
                  >
                    {currentTrack && currentTrack.id === meditation.id && isPlaying ? <FaPause className="w-6 h-6" /> : <FaPlay className="w-6 h-6" />}
                  </button>
                  <TrackOptionsMenu track={meditation} onRemove={() => handleRemoveTrack(meditation.id)} />
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">This playlist is empty.</p>
          )}
        </div>
      </div>
      <CreatePlaylistModal 
        isOpen={isEditModalOpen} 
        onClose={() => setIsEditModalOpen(false)}
        editPlaylist={playlist}
        onPlaylistUpdate={handleEditPlaylist}
      />
    </div>
  );
}

export default PlaylistView;