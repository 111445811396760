import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import DashboardLayout from './components/DashboardLayout';
import DashboardHome from './components/DashboardHome';
import ChatbotSelection from './components/ChatbotSelection';
import ChatInterface from './components/ChatInterface';
import Streaming from './components/Streaming';
import Login from './Login';
import GratitudeJournal from './components/GratitudeJournal';
import AccountManagement from './components/AccountManagement';
import LikedTracks from './components/LikedTracks';
import SubscriptionPage from './components/SubscriptionPage'; // Add this import
import SearchResults from './components/SearchResults';
import './App.css';
import { PlaylistProvider } from './PlaylistContext';
import PlaylistView from './components/PlaylistView'; // Make sure this import is present
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <PlaylistProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Login />} />
          <Route path="/subscription" element={<SubscriptionPage />} /> {/* Add this line */}
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardHome />} />
            <Route path="ai-chatbots" element={<ChatbotSelection />} />
            <Route path="search" element={<SearchResults />} />
            <Route path="streaming" element={<Streaming />} />
            <Route path="streaming/:albumName" element={<Streaming />} />
            <Route path="courses" element={<h2>Courses</h2>} />
            <Route path="gratitude-journal" element={<GratitudeJournal />} />
            <Route path="profile" element={<AccountManagement />} />
            <Route path="liked-tracks" element={<LikedTracks />} />
            <Route path="playlist/:playlistId" element={<PlaylistView />} />
          </Route>
          <Route path="/chat/:mindId" element={<DashboardLayout />}>
            <Route index element={<ChatInterface />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
        <Toaster position="bottom-right" />
      </div>
    </PlaylistProvider>
  );
}

export default App;