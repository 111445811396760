// dharmalab-app/src/components/Player.js
import React, { useEffect, useRef, useState } from 'react';
import { FaStepBackward, FaStepForward, FaPlay, FaPause } from 'react-icons/fa';
import { FiHeart } from 'react-icons/fi';
import LikeLoadingSpinner from './LikeLoadingSpinner';
import { supabase } from '../supabaseClient';
import TrackOptionsMenu from './TrackOptionsMenu';  // Import the TrackOptionsMenu component
import './Player.css';

function Player({ 
  currentTrack, 
  isPlaying, 
  setIsPlaying, 
  setIsPlayerVisible, 
  likedTracks, 
  handleLikeClick,  // Use this instead of handleLocalLikeClick
  loadingLikes, 
  setLoadingLikes,
  user
}) {
  const audioRef = useRef(new Audio());
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (currentTrack) {
      console.log('Current track in Player:', currentTrack);
      audio.src = currentTrack.audio_url;
      audio.load();
      setDuration(currentTrack.duration || 0);
      if (isPlaying) {
        audio.play()
          .then(() => console.log('Audio started playing'))
          .catch(error => {
            console.error('Error playing audio:', error);
            setError('Failed to play audio');
          });
      }
    } else {
      audio.pause();
      audio.src = '';
    }

    const handleEnded = () => {
      console.log('Audio playback ended');
      setIsPlaying(false);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => {
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.pause();
    };
  }, [currentTrack, isPlaying, setIsPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying && currentTrack) {
      audio.play()
        .then(() => console.log('Audio resumed playing'))
        .catch(error => {
          console.error('Error resuming audio:', error);
          setError('Failed to resume audio');
        });
    } else {
      audio.pause();
    }
  }, [isPlaying, currentTrack, setIsPlaying]);

  const formatTime = (seconds) => {
    if (isNaN(seconds)) return '00:00:00';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ].join(':');
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const newTime = (e.target.value / 100) * duration;
    audio.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleRewind = () => {
    const audio = audioRef.current;
    audio.currentTime = Math.max(audio.currentTime - 15, 0);
    setCurrentTime(audio.currentTime);
  };

  const handleFastForward = () => {
    const audio = audioRef.current;
    audio.currentTime = Math.min(audio.currentTime + 15, audio.duration);
    setCurrentTime(audio.currentTime);
  };

  const handlePlayerLikeClick = (e) => {
    e.stopPropagation();
    if (currentTrack && user) {
      handleLikeClick(currentTrack);
    }
  };

  if (!currentTrack) return null;

  const isLoadingLike = loadingLikes[currentTrack.id];
  const isLiked = likedTracks[currentTrack.id];

  return (
    <div className="player">
      <div className="player-left">
        <img src={currentTrack.artwork_url} alt={currentTrack.title} className="player-artwork" />
        <div className="player-info">
          <div className="player-title">{currentTrack.title}</div>
          <div className="player-artist">{currentTrack.artist}</div>
          {error && <div className="player-error">{error}</div>}
        </div>
      </div>
      <div className="player-center">
        <div className="player-progress-wrapper">
          <div className="player-progress">
            <div 
              className="progress-bar" 
              style={{width: `${(currentTime / duration) * 100}%`}}
            ></div>
            <input
              type="range"
              min="0"
              max="100"
              value={(currentTime / duration) * 100 || 0}
              onChange={handleProgressChange}
              className="progress-input"
            />
          </div>
          <div className="time-display">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
      </div>
      <div className="player-right">
        <div className="player-controls">
          <button 
            className={`like-button ${isLiked ? 'liked' : ''}`} 
            onClick={handlePlayerLikeClick}
            disabled={isLoadingLike}
          >
            {isLoadingLike ? (
              <LikeLoadingSpinner />
            ) : (
              <FiHeart className={`heart-icon ${isLiked ? 'liked' : ''}`} />
            )}
          </button>
          <button className="control-button" onClick={handleRewind}>
            <FaStepBackward />
          </button>
          <button className="control-button" onClick={() => setIsPlaying(!isPlaying)}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button className="control-button" onClick={handleFastForward}>
            <FaStepForward />
          </button>
        </div>
        <TrackOptionsMenu />
      </div>
    </div>
  );
}

export default Player;