import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useOutletContext, Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { quotes } from './quotes';
import { FaPlay, FaPause } from 'react-icons/fa';
import './DashboardHome.css';

function DashboardHome() {
  const { setCurrentTrack, setIsPlaying } = useOutletContext();
  const [user, setUser] = useState(null);
  const [activities, setActivities] = useState([]);
  const [recentTracks, setRecentTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quote, setQuote] = useState(null);
  const [journalSummary, setJournalSummary] = useState({
    gratitude: 0,
    happenings: 0,
    intentions: 0,
    todo: 0
  });
  const [hoveredTrack, setHoveredTrack] = useState(null);
  const [zenLevel, setZenLevel] = useState("");

  useEffect(() => {
    fetchUser();
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
  }, []);

  useEffect(() => {
    if (user) {
      fetchActivities();
      fetchJournalSummary();
      fetchRecentTracks();
    }
  }, [user]);

  async function fetchUser() {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      setUser(user);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to fetch user information');
    } finally {
      setLoading(false);
    }
  }

  async function fetchActivities() {
    try {
      const { data, error } = await supabase
        .from('user_activities')
        .select('*')
        .eq('user_id', user.id)
        .or('activity_type.eq.chat_started,activity_type.eq.chat_resumed,activity_type.eq.message_sent')
        .order('created_at', { ascending: false });

      if (error) throw error;

      const groupedActivities = data.reduce((acc, activity) => {
        const mindName = activity.details.split(' with ')[1];
        if (!acc[mindName] || new Date(activity.created_at) > new Date(acc[mindName].created_at)) {
          acc[mindName] = activity;
        }
        return acc;
      }, {});

      setActivities(Object.values(groupedActivities).sort((a, b) => 
        new Date(b.created_at) - new Date(a.created_at)
      ));
    } catch (error) {
      console.error('Error fetching activities:', error);
      setError('Failed to fetch recent chats');
    }
  }

  async function fetchJournalSummary() {
    try {
      const { data, error } = await supabase
        .from('gratitude_entries')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;

      const summary = data.reduce((acc, entry) => {
        acc.gratitude += entry.gratitude?.length || 0;
        acc.happenings += entry.happenings?.length || 0;
        acc.intentions += entry.intentions?.length || 0;
        acc.todo += entry.todo?.length || 0;
        return acc;
      }, { gratitude: 0, happenings: 0, intentions: 0, todo: 0 });

      setJournalSummary(summary);

      // Calculate Zen Level
      const totalEntries = summary.gratitude + summary.happenings + summary.intentions;
      const level = calculateLevel(totalEntries);
      setZenLevel(level);
    } catch (error) {
      console.error('Error fetching journal summary:', error);
    }
  }

  function calculateLevel(total) {
    const levels = [
      { name: "Seeker", range: [0, 100] },
      { name: "Novice", range: [101, 200] },
      { name: "Apprentice", range: [201, 300] },
      { name: "Student", range: [301, 400] },
      { name: "Adept", range: [401, 500] },
      { name: "Disciple", range: [501, 600] },
      { name: "Keeper", range: [601, 700] },
      { name: "Explorer", range: [701, 800] },
      { name: "Guardian", range: [801, 900] },
      { name: "Sage", range: [901, 1000] },
      { name: "Harbinger", range: [1001, 1100] },
      { name: "Master", range: [1101, 1200] },
      { name: "Pillar", range: [1201, 1300] },
      { name: "Mentor", range: [1301, 1400] },
      { name: "Conductor", range: [1401, 1500] },
      { name: "Bearer", range: [1501, 1600] },
      { name: "Enlightened", range: [1601, 1700] },
      { name: "Embodiment", range: [1701, 1800] },
      { name: "Vessel", range: [1801, 1900] },
      { name: "Beacon", range: [1901, 2000] },
      { name: "Bringer", range: [2001, 2100] },
      { name: "Guide", range: [2101, 2200] },
      { name: "Soul", range: [2201, 2300] },
      { name: "Ascendant", range: [2301, 2400] },
      { name: "Zen Master", range: [2401, Infinity] }
    ];

    for (let level of levels) {
      if (total >= level.range[0] && total <= level.range[1]) {
        return level.name;
      }
    }
    return "Zen Master";
  }

  async function fetchRecentTracks() {
    try {
      const { data, error } = await supabase
        .from('recently_played_tracks')
        .select('*')
        .eq('user_id', user.id)
        .order('played_at', { ascending: false });

      if (error) throw error;

      const uniqueTracks = data.reduce((acc, track) => {
        if (!acc[track.track_id] || new Date(track.played_at) > new Date(acc[track.track_id].played_at)) {
          acc[track.track_id] = track;
        }
        return acc;
      }, {});

      setRecentTracks(Object.values(uniqueTracks).slice(0, 5));
    } catch (error) {
      console.error('Error fetching recent tracks:', error);
    }
  }

  async function playPause(track) {
    try {
      const { data, error } = await supabase
        .from('meditations')
        .select('id, audio_url, duration')
        .eq('id', track.track_id)
        .single();

      if (error) throw error;

      setCurrentTrack({
        id: data.id,
        title: track.title,
        artist: track.artist,
        album: track.album,
        artwork_url: track.artwork_url,
        audio_url: data.audio_url,
        duration: data.duration,
      });
      setIsPlaying(true);

      await supabase.from('recently_played_tracks').upsert({
        user_id: user.id,
        track_id: track.track_id,
        title: track.title,
        artist: track.artist,
        album: track.album,
        artwork_url: track.artwork_url,
        played_at: new Date().toISOString()
      }, {
        onConflict: 'user_id,track_id'
      });

      if (error) throw error;

      fetchRecentTracks();
    } catch (error) {
      console.error('Error adding track to recently played:', error);
    }
  }

  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <div className="dashboard-home">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-bold mb-2">Welcome, <span className="text-indigo-600">{user?.user_metadata?.first_name || user?.email || 'User'}</span></h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
        <Link to="/dashboard/gratitude-journal" className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center">
          <h2 className="text-xl font-semibold mb-4">Journal Summary</h2>
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 text-center w-full">
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.gratitude}</p>
              <p className="text-gray-600">Gratitudes</p>
            </div>
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.happenings}</p>
              <p className="text-gray-600">Happenings</p>
            </div>
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.intentions}</p>
              <p className="text-gray-600">Intentions</p>
            </div>
            <div className="journal-summary-item">
              <p className="text-2xl font-bold text-indigo-600">{journalSummary.todo}</p>
              <p className="text-gray-600">To Do</p>
            </div>
          </div>
        </Link>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-center items-center">
          <h2 className="text-xl font-semibold mb-4">Your Zen Level</h2>
          <p className="text-3xl font-bold text-indigo-600">{zenLevel}</p>
          <p className="text-gray-600 mt-2">Keep journaling to level up!</p>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-center">
          {quote && (
            <>
              <p className="text-2xl italic mb-4 font-serif leading-relaxed">&ldquo;{quote.quote}&rdquo;</p>
              <p className="text-right text-gray-600 text-lg">— {quote.author}</p>
            </>
          )}
        </div>
      </div>
      
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <h2 className="text-2xl font-semibold mr-2">Recent Chats</h2>
          <Link to="/chat" className="text-3xl text-indigo-600 hover:text-indigo-800">⊕</Link>
        </div>
        <div className="flex overflow-x-auto pb-4 space-x-4">
          {activities.length > 0 ? (
            activities.slice(0, 5).map((activity, index) => {
              const mindName = activity.details.split(' with ')[1];
              const mindId = mindName.replace(' ', '-');
              const mindIcon = require(`../components/mind-icons/${mindId}.png`);
              return (
                <div key={index} className="flex-none w-64">
                  <Link 
                    to={`/chat/${mindId.toLowerCase()}`} 
                    className="block w-full h-full bg-white shadow-md rounded-lg p-4 text-center relative overflow-hidden transition-transform hover:scale-105"
                  >
                    <img src={mindIcon} alt={`${mindName} icon`} className="w-24 h-24 mx-auto mb-2 rounded-full" />
                    <p className="text-sm font-medium">{mindName}</p>
                  </Link>
                </div>
              );
            })
          ) : (
            <p className="text-gray-600">No recent chats. Start a new conversation!</p>
          )}
        </div>
      </div>
      
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <h2 className="text-2xl font-semibold mr-2">Recently Played Tracks</h2>
          <Link to="/streaming" className="text-3xl text-indigo-600 hover:text-indigo-800">⊕</Link>
        </div>
        <div className="flex overflow-x-auto pb-4 space-x-4">
          {recentTracks.length > 0 ? (
            recentTracks.map((track, index) => (
              <div 
                key={index} 
                className="flex-none w-64 bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transition-transform hover:scale-105"
                onClick={() => playPause(track)}
                onMouseEnter={() => setHoveredTrack(track.id)}
                onMouseLeave={() => setHoveredTrack(null)}
              >
                <div className="relative">
                  <img src={track.artwork_url} alt={`${track.album} cover`} className="w-full h-40 object-cover" />
                  {hoveredTrack === track.id && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <button className="text-white text-4xl">▶</button>
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg mb-1 truncate">{track.title}</h3>
                  <p className="text-gray-600 text-sm truncate">{track.artist}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No recently played tracks.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardHome;