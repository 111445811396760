import React, { useState, useEffect, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FaPlay, FaPause } from 'react-icons/fa';
import { FiHeart } from 'react-icons/fi';
import LoadingSpinner from './LoadingSpinner';
import LikeLoadingSpinner from './LikeLoadingSpinner';
import { Button } from "../components/ui/button";
import TrackOptionsMenu from './TrackOptionsMenu';  // Import the new component

function LikedTracks() {
  const { 
    currentTrack,
    setCurrentTrack, 
    isPlaying, 
    setIsPlaying, 
    likedTracks,
    handleLikeClick,
    loadingLikes,
    setLoadingLikes
  } = useOutletContext();
  const [user, setUser] = useState(null);
  const [likedTracksData, setLikedTracksData] = useState([]);
  const [hoveredTrack, setHoveredTrack] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkUser = useCallback(async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      console.error('Error fetching user:', error);
      return null;
    }
    return user;
  }, []);

  const fetchLikedTracks = useCallback(async () => {
    if (!user) return;
    
    try {
      const { data, error } = await supabase
        .from('meditations')
        .select('id, album, artist, artwork_url, title, audio_url, duration')
        .in('id', Object.keys(likedTracks));

      if (error) throw error;

      setLikedTracksData(data);
    } catch (error) {
      console.error('Error fetching liked tracks:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user, likedTracks]);

  useEffect(() => {
    async function initializeComponent() {
      const user = await checkUser();
      if (user) {
        setUser(user);
        fetchLikedTracks();
      }
    }

    initializeComponent();
  }, [checkUser, fetchLikedTracks]);

  const playPause = (track) => {
    if (currentTrack && currentTrack.id === track.id) {
      setIsPlaying(!isPlaying);
    } else {
      setCurrentTrack(track);
      setIsPlaying(true);
    }
  };

  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    const parts = [
      hours > 0 ? hours.toString().padStart(2, '0') : null,
      minutes.toString().padStart(2, '0'),
      remainingSeconds.toString().padStart(2, '0')
    ].filter(Boolean);
    
    return parts.join(':');
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="flex flex-col md:flex-row items-center md:items-start mb-8">
          <div className="w-64 h-64 flex items-center justify-center bg-red-100 rounded-lg mb-4 md:mb-0 md:mr-8">
            <span className="text-8xl">❤️</span>
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-2">Liked Tracks</h1>
            <p className="text-xl text-gray-600 mb-4">These are your liked tracks</p>
          </div>
        </div>
        <div className="space-y-4">
          {likedTracksData.length > 0 ? (
            likedTracksData.map((track) => (
              <div key={track.id} className="flex items-center justify-between p-4 bg-gray-100 rounded-lg">
                <div className="flex items-center flex-grow">
                  <img src={track.artwork_url} alt={`${track.album} cover`} className="w-16 h-16 object-cover rounded-md mr-4" />
                  <div>
                    <h3 className="font-semibold">{track.title}</h3>
                    <p className="text-gray-600 text-sm">{track.artist}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <span className="text-gray-500">{formatDuration(track.duration)}</span>
                  <button 
                    className={`text-red-500 hover:text-red-600 focus:outline-none ${likedTracks[track.id] ? 'text-red-600' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLikeClick(track);
                    }}
                    disabled={loadingLikes[track.id]}
                  >
                    {loadingLikes[track.id] ? (
                      <LikeLoadingSpinner />
                    ) : (
                      <FiHeart className={`w-6 h-6 ${likedTracks[track.id] ? 'fill-current' : ''}`} />
                    )}
                  </button>
                  <button 
                    className={`text-gray-600 hover:text-gray-800 focus:outline-none ${currentTrack && currentTrack.id === track.id && isPlaying ? 'text-blue-500' : ''}`}
                    onClick={() => playPause(track)}
                  >
                    {currentTrack && currentTrack.id === track.id && isPlaying ? <FaPause className="w-6 h-6" /> : <FaPlay className="w-6 h-6" />}
                  </button>
                  <TrackOptionsMenu track={track} />  {/* Add the TrackOptionsMenu here */}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No liked tracks yet.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LikedTracks;